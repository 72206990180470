<template>
  <div
    v-expose="{ id: '1-8-1-44' }"
    :class="classes"
  >
    <div class="cart-bnpl-popover__content">
      <div
        v-if="placeOrderDesc"
        class="cart-bnpl-popover__text"
      >
        <TextMarquee width="100%">
          <span v-html="placeOrderDesc"></span>
        </TextMarquee>
      </div>
      <div
        v-if="paymentFrontPaymentList.length"
        class="cart-bnpl-popover__icon"
      >
        <template v-for="payment in paymentFrontPaymentList">
          <img
            v-if="payment.logoUrl"
            :key="payment.paymentCode"
            :src="payment.logoUrl"
          />
        </template>
      </div>
      <div
        v-if="showClose"
        v-tap="{ daId: '1-8-1-45' }"
        class="cart-bnpl-popover__close"
        @click="handleClose"
      >
        <sui_icon_close_16px_2
          size="12px"
          color="#666666"
        />
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @description 购物车结算 BNPL Popover 提示
 */
export default {
  name: 'CartBNPLPopover',
}
</script>
<script setup>
import { computed } from 'vue'
import { sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import TextMarquee from '../common/TextMarquee.vue'
import { useStore } from '../../hooks/useStore'

const { state, getters } = useStore()
const emit = defineEmits(['close'])
const props = defineProps({
  // 箭头方向
  placement: {
    type: String,
    default: 'top',
    validator(val) {
      return ['top', 'bottom'].includes(val)
    }
  },
  // 是否展示箭头
  showArrow: {
    type: Boolean,
    default: true,
  },
  // 是否显示关闭按钮
  showClose: {
    type: Boolean,
    default: true,
  },
  placeOrderDesc: {
    type: String,
    default: '',
  },
  paymentFrontPaymentList: {
    type: Array,
    default: null,
  },
})

const placeOrderDesc = computed(() => props.placeOrderDesc || state.checkoutState.bnplCheckoutInfo?.checkoutBubbleInfo?.tipText || '')
const paymentFrontPaymentList = computed(() => props.paymentFrontPaymentList || getters['paymentFrontPaymentList'] || [])
const classes = computed(() => [
  'cart-bnpl-popover',
  props.showArrow && `cart-bnpl-popover_${props.placement}`
])
const handleClose = () => {
  emit('close')
}

</script>

<style lang="less">
.cart-bnpl-popover {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 28/37.5rem;
  &_bottom {
    padding-bottom: 5/37.5rem;
    .cart-bnpl-popover__content {
      &:before {
        top: 100%;
        right: 72/37.5rem;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #767676 transparent transparent transparent;
      }
      &:after {
        top: calc(100% - 1px);
        right: 72/37.5rem;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #fff transparent transparent transparent;
      }
    }
  }
  &_top {
    margin-top: 5/37.5rem;
    .cart-bnpl-popover__content {
      &:before {
        bottom: 100%;
        right: 28/37.5rem;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #767676 transparent;
      }
      &:after {
        bottom: calc(100% - 0.5px);
        right: 28/37.5rem;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #fff transparent;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 24/37.5rem;
    padding: 0 8/37.5rem;
    .border-dpr(border, 1px, #767676);
    border-radius: 2/37.5rem;
    background-color: #fff;
    margin-left: auto;
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
    }
  }

  &__text {
    .font-dpr(24px);
    color: @sui_color_main;
    margin-right: 4/37.5rem;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 8/37.5rem;
    img {
      width: 24/37.5rem;
      height: 16/37.5rem;
      object-fit: contain;
      .border-dpr(border, 1px, #e5e5e5);
      &:nth-child(n+1) {
        margin-left: 4/37.5rem;
      }
    }
  }

  &__close {
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12/37.5rem;
    height: 12px/37.5rem;
  }
}
</style>
